/* latin-ext */
@font-face {
    font-family: 'Cutive Mono';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/cutive-mono/m8JWjfRfY7WVjVi2E-K9H6RMTm663A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Cutive Mono';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/cutive-mono/m8JWjfRfY7WVjVi2E-K9H6RCTm4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  html {
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }
  
  html, body{
      width:100%;
      padding: 0;
      margin: 0;
      line-height: 1.5rem;
  }
  
  body{
      margin: 0;
      font-weight: 100;
      font-size: 18px;
      background-color: rgb(28,27,27);
      color: #FFF;
      font-family: 'Cutive Mono', monospace;
  }
  
  * {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
     font-variant-ligatures: none;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  a {
      color: #FFF;
      text-decoration: underline;
  }
  
  a:hover {
      text-decoration: underline;
      color: #FFF;
  }
  
  p {
      margin: 1rem 0;
  }
  
  h1, h2 {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
  }
  
  #main {
      width: 50%;
      padding-left: 2rem;
      padding-top: 2rem;
  }
  
  @media (max-width: 992px) {
      #main {
          width: 75%;
      }
  }
  
  hr {
     background-color: transparent;
     height: 0.5rem; 
     line-height: 0.5rem;
     overflow: hidden;
     border: 0;
     
  }
  hr:before {
    content: "----------------------------------------------------------------------------------------------";
  }
  
  @media (max-width: 768px) {
      #main {
          width: 100%;
          padding-right: 2rem;
      }
  }
  
  #bot-input, #bot-input:focus {
      border: 0; 
      outline: none;
      background-color: transparent; 
      color: #FFF;
      width: 100%;
      min-height: 1rem;
      font-family: 'Cutive Mono', monospace;
      font-size: 1rem;
  }
  
  #bot-input:focus {
      background-color: rgb(30,32,32);
  }
  
  #blinking-cursor {
    font-weight: 100;
    font-size: 1rem;
    color: #FFF;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
    position: absolute;
  }

  .hide {
    display: none;
  }
  
  @keyframes "blink" {
    from, to {
      color: transparent;
    }
    50% {
      color: #FFF;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: #FFF;
    }
  }
  
  @-webkit-keyframes "blink" {
    from, to {
      color: transparent;
    }
    50% {
      color: #FFF;
    }
  }
  
  @-ms-keyframes "blink" {
    from, to {
      color: transparent;
    }
    50% {
      color: #FFF;
    }
  }
  
  @-o-keyframes "blink" {
    from, to {
      color: transparent;
    }
    50% {
      color: #FFF;
    }
  }


.link_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.link_label {
  color: #4cdb44;
}